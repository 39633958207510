import axios from "axios";

import { headers, host } from "./shared";

export const createClass = async (schoolClass, schoolId, accessToken) => {
  try {
    return await axios.post(
      `${host}/api/schools/${schoolId}/classes`,
      { school_class: schoolClass },
      headers(accessToken),
    );
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const getClasses = async (schoolId, accessToken) => {
  return await axios.get(
    `${host}/api/schools/${schoolId}/classes`,
    headers(accessToken),
  );
};

export const getClass = async (classId, schoolId, accessToken) => {
  return await axios.get(
    `${host}/api/schools/${schoolId}/classes/${classId}`,
    headers(accessToken),
  );
};

export const addClassMember = async (
  studentId,
  classId,
  schoolId,
  accessToken,
) => {
  return await axios.post(
    `${host}/api/schools/${schoolId}/classes/${classId}/members`,
    { class_member: { student_id: studentId } },
    headers(accessToken),
  );
};

export const deleteStudentFromClass = async (
  studentId,
  classId,
  schoolId,
  accessToken,
) => {
  let response;
  try {
    response = await axios.delete(
      `${host}/api/schools/${schoolId}/classes/${classId}/members/${studentId}`,
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const getClassMembers = async (classId, schoolId, accessToken) => {
  return await axios.get(
    `${host}/api/schools/${schoolId}/classes/${classId}/members`,
    headers(accessToken),
  );
};

export const createLesson = async (lesson, accessToken) => {
  try {
    return await axios.post(
      `${host}/api/lessons`,
      { lesson },
      headers(accessToken),
    );
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const getLessons = async (classId, accessToken) => {
  return await axios.get(
    `${host}/api/lessons?school_class_id=${classId}`,
    headers(accessToken),
  );
};

export const getLesson = async (lessonId, accessToken) => {
  const response = await axios.get(
    `${host}/api/lessons/${lessonId}`,
    headers(accessToken),
  );
  return response;
};

export const updateLesson = async (lesson, accessToken) => {
  return await axios.put(
    `${host}/api/lessons/${lesson.id}`,
    { lesson },
    headers(accessToken),
  );
};

export const getInvitation = async (tokenId, accessToken) => {
  return await axios.get(
    `${host}/api/teacher_invitations/${tokenId}`,
    headers(accessToken),
  );
};

export const acceptInvitation = async (tokenId, accessToken) => {
  return await axios.put(
    `${host}/api/teacher_invitations/${tokenId}/accept`,
    {},
    headers(accessToken),
  );
};
