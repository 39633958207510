import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createClass,
  createStudent,
  getClasses,
  getSchool,
  getSchoolMembers,
  getSchoolStudents,
  getUserSchool,
  deleteStudentFromClass,
  putStudentDetails,
} from "../../utils/apiCallHandler";
import { actionLoadingStates } from "../../utils/stateEnums";

export const initialState = {
  loading: actionLoadingStates.idle,
  justLoaded: false,
};

export const loadSchool = createAsyncThunk(
  `school/load`,
  async ({ id, accessToken }) => {
    if (id) {
      return await getSchool(id, accessToken);
    } else {
      return await getUserSchool(accessToken);
    }
  },
);

export const createNewStudent = createAsyncThunk(
  `school/createStudent`,
  async ({ student, schoolId, accessToken }) => {
    const response = await createStudent(student, schoolId, accessToken);
    return response?.data;
  },
);

export const createNewClass = createAsyncThunk(
  `school/createClass`,
  async ({ schoolClass, schoolId, accessToken }) => {
    const response = await createClass(schoolClass, schoolId, accessToken);
    return response?.data;
  },
);

export const loadClasses = createAsyncThunk(
  `school/loadClasses`,
  async ({ schoolId, accessToken }) => {
    const response = await getClasses(schoolId, accessToken);
    return response?.data;
  },
);

export const loadSchoolMembers = createAsyncThunk(
  `school/loadMembers`,
  async ({ schoolId, accessToken }) => {
    const response = await getSchoolMembers(schoolId, accessToken);
    return response?.data;
  },
);

export const loadSchoolStudents = createAsyncThunk(
  `school/loadStudents`,
  async ({ schoolId, accessToken }) => {
    const response = await getSchoolStudents(schoolId, accessToken);
    return response?.data;
  },
);

export const updateStudentDetails = createAsyncThunk(
  `school/updateStudentDetails`,
  async ({ student, studentId, schoolId, accessToken }) => {
    const response = await putStudentDetails(
      student,
      studentId,
      schoolId,
      accessToken,
    );
    return response?.data;
  },
);

export const removeStudentFromClass = createAsyncThunk(
  `school/removeStudentFromClass`,
  async ({ studentId, classId, schoolId, accessToken }) => {
    const response = await deleteStudentFromClass(
      studentId,
      classId,
      schoolId,
      accessToken,
    );

    return response?.data;
  },
);

export const extraReducers = (builder) => {
  builder.addCase("school/load/pending", (state) => {
    return { ...state, loading: "pending" };
  });
  builder.addCase("school/load/fulfilled", (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: "succeeded",
      justLoaded: true,
    };
  });
  builder.addCase("school/load/rejected", (state, action) => {
    return { ...state, error: action.error, loading: "failed" };
  });
  builder.addCase("school/loadClasses/fulfilled", (state, action) => {
    state.classes = action.payload;
  });
  builder.addCase("school/loadMembers/fulfilled", (state, action) => {
    state.members = action.payload;
  });
  builder.addCase("school/loadStudents/fulfilled", (state, action) => {
    state.members = action.payload;
  });
};

const setSchoolCode = (state, action) => {
  state.code = action.payload;
};

export const reducers = {
  setSchoolCode,
};
