import axios from "axios";

import { headers, host } from "./shared";

export const createStudent = async (student, schoolId, accessToken) => {
  let response;
  try {
    response = await axios.post(
      `${host}/api/schools/${schoolId}/students`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const putStudentDetails = async (
  student,
  studentId,
  schoolId,
  accessToken,
) => {
  let response;
  try {
    response = await axios.put(
      `${host}/api/schools/${schoolId}/students/${studentId}`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};
