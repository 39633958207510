import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSchool } from "../redux/reducers/schoolReducers";
import { isLoading } from "../utils/loadingHelpers";

const useSchool = ({ id, accessToken }) => {
  const dispatch = useDispatch();
  const school = useSelector((state) => state.school);

  useEffect(() => {
    if (!accessToken) return; // Avoid dispatching the action if there is no access token
    if (school?.justLoaded || isLoading(school?.loading)) return; // Avoid dispatching the action if the school is already loaded or loading
    dispatch(loadSchool({ id, accessToken }));
  }, [id, accessToken, dispatch, school?.justLoaded, school?.loading]);
};

export default useSchool;
